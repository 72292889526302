import { Title } from '@solidjs/meta';
import { Checkbox, CheckboxGroup, Label } from '@troon/ui';
import { StyleCard } from '../_style-card';

export default function CheckboxPage() {
	return (
		<>
			<Title>Checkbox | Forms | Style guide | Troon</Title>
			<h1 class="text-3xl font-semibold">Checkbox</h1>
			<div class="mb-8 grid grid-cols-12 gap-4">
				<StyleCard title="Checkbox">
					<Checkbox name="check">
						<Label>A single checkbox</Label>
					</Checkbox>
				</StyleCard>

				<StyleCard title="CheckboxGroup">
					<CheckboxGroup name="checkbox" required>
						<Label>A group of checkboxes</Label>
						<Checkbox>
							<Label>Select one</Label>
						</Checkbox>
						<Checkbox>
							<Label>Or many</Label>
						</Checkbox>
					</CheckboxGroup>
				</StyleCard>
			</div>
		</>
	);
}
